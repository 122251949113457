/*
 * TAKEDA CONFIDENTIAL – Highly Restricted: Do not distribute without prior approval
 *
 * © Copyright (2024) Takeda. All Rights Reserved
 */

import { MS_IN_MIN } from '../../domain/dates/dates.constants';

export const SESSION_EXPIRATION_TIMER_IN_MIN = 60;
export const SESSION_EXPIRATION_TIMER_IN_MS =
  SESSION_EXPIRATION_TIMER_IN_MIN * MS_IN_MIN;

export const SESSION_REFRESH_THRESHOLD_IN_MIN = 5;
export const SESSION_REFRESH_THRESHOLD_IN_MS =
  SESSION_REFRESH_THRESHOLD_IN_MIN * MS_IN_MIN;

export const LOCALE_COOKIE = 'vdz.locale.chosen_locale';
export const TERMS_ACCEPTANCE_DATE_COOKIE = 'vdz.terms.acceptance.date';
