/*
 * TAKEDA CONFIDENTIAL – Highly Restricted: Do not distribute without prior approval
 *
 * © Copyright (2023) Takeda. All Rights Reserved
 */

import queryString from 'query-string';
import React, { useEffect, useRef, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { useLocation, useNavigate } from 'react-router-dom';

import { useOktaAuth } from '@okta/okta-react';

import {
  getLoginTypesForCountry, getTakedaId, getUserId, isDocCheckLoginType, validateDocCheckLogin,
} from '../../../domain/authentication/authentication.domain';
import { DocCheckErrorType, LoginType } from '../../../domain/authentication/authentication.types';
import { replaceUrl } from '../../../domain/browser.domain';
import { MS_IN_S } from '../../../domain/dates/dates.constants';
import { compareDates, toDate } from '../../../domain/dates/dates.domain';
import { getCountryConfiguration } from '../../../domain/global-config.domain';
import {
  getRenewedSessionExpiration, getSessionDate, getSessionTimestamp, setTrackedTakedaId,
  setTrackedUserId,
} from '../../../domain/tracking/tracking.domain';
import { getGlobalConfigData } from '../../../services/global-config/global-config.service';
import { saveVisit } from '../../../services/tracking/tracking.service';
import { DOC_CHECK_LOGIN_PAGE, LOGIN_PAGE } from '../../navigation/navigation.constants';
import {
  useDocCheckAccessToken,
} from '../../shared/hooks/use-doc-check-access-token/use-doc-check-access-token.hook';
import { useGlobalConfig } from '../../shared/hooks/use-global-config/use-global-config.hook';
import { useLocale } from '../../shared/hooks/use-locale/use-locale.hook';
import { useSession } from '../../shared/hooks/use-session/use-session.hook';
import {
  VdzTermsUpdatedConfirmation,
} from '../../terms/components/terms-updated-confirmation/terms-updated-confirmation.component';
import { SESSION_EXPIRATION_TIMER_IN_MS } from '../authentication.constants';

type AuthProps = {
  success: JSX.Element;
  loading: JSX.Element;
};

export default function Authenticated({ success, loading }: AuthProps) {
  const { authState, oktaAuth } = useOktaAuth();
  const {
    globalConfig: { countryConfiguration },
    setGlobalConfig,
  } = useGlobalConfig();
  const {
    docCheckAccessToken,
    clearDocCheckAccessToken,
    setDocCheckAccessToken,
  } = useDocCheckAccessToken();
  const [authenticated, setAuthenticated] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();
  const takedaIdRefreshTimeout = useRef<NodeJS.Timeout | undefined>();
  const { clearLocale, country, language, locale } = useLocale();
  const {
    getTimestamp,
    setTimestamp,
    setExpirationDate,
    removeTimestamp,
    removeExpirationDate,
  } = useSession();

  const loginTypes = country
    ? getLoginTypesForCountry(country, countryConfiguration)
    : undefined;

  const searchParams = queryString.parse(window.location.search);
  const isDocCheckLogin = isDocCheckLoginType(
    searchParams,
    loginTypes,
    docCheckAccessToken
  );
  const docCheckAuthenticationCode = isDocCheckLogin
    ? (searchParams.code as string)
    : undefined;

  const logoutAndNavigateToCountryPicker = () => {
    oktaAuth?.closeSession().then(() => {
      redirectToLogin();
    });
    clearDocCheckAccessToken();
    setAuthenticated(false);
    clearLocale();
    redirectToLogin();
  };

  const onInactivityTimeout = () => {
    if (authState?.isAuthenticated) {
      oktaAuth?.closeSession().then(() => {
        redirectToLogin();
      });
      oktaAuth.authStateManager?.unsubscribe();
    }

    if (docCheckAccessToken) {
      clearDocCheckAccessToken();
      setAuthenticated(false);
      redirectToLogin();
    }

    removeTimestamp();
    removeExpirationDate();
  };

  const loadConfigData = async () => {
    setGlobalConfig(await getGlobalConfigData());
  };

  const onAction = async () => {
    const oldSessionDate = getTimestamp();
    const newSessionDate = getSessionDate();

    setExpirationDate(getRenewedSessionExpiration());

    if (newSessionDate.getTime() !== oldSessionDate?.getTime()) {
      setTimestamp(newSessionDate);

      if (authenticated) {
        // fetch config right after login, in case the country was disabled
        // while they still had the app open and it did not refresh
        loadConfigData();

        await saveVisitEvent();
      }
    }
  };

  useIdleTimer({
    disabled: !authenticated,
    timeout: SESSION_EXPIRATION_TIMER_IN_MS,
    onIdle: onInactivityTimeout,
    onAction: onAction,
  });

  useEffect(() => {
    if (!getCountryConfiguration(country, countryConfiguration)) {
      logoutAndNavigateToCountryPicker();
    }

    const validate = async () => {
      const readiedLoginTypes = loginTypes
        ?.map((type) => authMapping[type])
        .filter(({ ready }) => ready());

      const validationExists = !!loginTypes;
      const validationReady = !!readiedLoginTypes?.length;

      if (validationReady) {
        const results = await Promise.all(
          readiedLoginTypes.map(({ validate }) => validate())
        );
        const valid = results.includes(true);
        const isDocCheckError = Object.values(DocCheckErrorType).find(
          (errorType) => errorType === results[0]
        );
        setAuthenticated(valid);

        if (authState?.isAuthenticated) {
          scheduleTakedaIDTokenRefresh();
        }

        if (!valid && !isDocCheckError) redirectToLogin();
      } else if (!validationExists) {
        redirectToLogin();
      }
    };

    validate();
  }, [authState, country, location]);

  useEffect(() => {
    if (country && language && authState?.isAuthenticated === true) {
      const userId = getUserId(authState.idToken);
      const takedaId = getTakedaId(authState.idToken);
      setTrackedUserId(userId);
      setTrackedTakedaId(takedaId);
    }
  }, [country, authState]);

  const isTakedaIDReady = () => {
    return !!authState;
  };

  const validateTakedaID = async () => {
    return authState?.isAuthenticated === true;
  };

  const isDocCheckReady = () => {
    return isDocCheckLogin;
  };

  const isUnauthenticated = () => true;

  const validateDocCheck = async () => {
    try {
      const accessToken = await validateDocCheckLogin(
        country as string,
        countryConfiguration,
        docCheckAuthenticationCode,
        docCheckAccessToken
      );

      if (docCheckAccessToken) {
        return validateExistingDocCheckAccessToken(accessToken);
      } else if (isDocCheckLogin && accessToken) {
        return validateNewDocCheckAccessToken(accessToken);
      } else {
        return false;
      }
    } catch {
      if (country) {
        replaceUrl('/#/');
        navigate(
          DOC_CHECK_LOGIN_PAGE.replace(/:country/, country.toLowerCase()),
          {
            state: { authErrorType: DocCheckErrorType.RequestError },
          }
        );
        return DocCheckErrorType.RequestError;
      }
    }
  };

  const validateExistingDocCheckAccessToken = (accessToken?: string) => {
    const validated = !!accessToken;
    if (!validated) clearDocCheckAccessToken();

    setAuthenticated(validated);
    return validated;
  };

  const validateNewDocCheckAccessToken = (accessToken?: string) => {
    const validated = !!accessToken;
    if (validated) {
      setDocCheckAccessToken(accessToken);
    }

    replaceUrl('/#/');

    setAuthenticated(validated);
    return validated;
  };

  const redirectToLogin = () => {
    if (location.pathname !== LOGIN_PAGE) {
      navigate(LOGIN_PAGE);
    }
  };

  const getLoginType = async () => {
    if (loginTypes?.length) {
      for (const loginType of loginTypes) {
        const loggedIn = await authMapping[loginType as LoginType].validate();
        if (loggedIn) return loginType as LoginType;
      }
    }

    return LoginType.Unauthenticated;
  };

  const saveVisitEvent = async () => {
    await saveVisit({
      sessionTimestamp: getSessionTimestamp(),
      loginType: await getLoginType(),
    });
  };

  const scheduleTakedaIDTokenRefresh = async () => {
    if (takedaIdRefreshTimeout.current) {
      clearTimeout(takedaIdRefreshTimeout.current);
    }

    const expiration = authState?.accessToken?.expiresAt;
    if (expiration) {
      const expirationDate = toDate(expiration * MS_IN_S);
      const refreshTimeout = compareDates(expirationDate);

      takedaIdRefreshTimeout.current = setTimeout(
        async () => await oktaAuth.tokenManager.renew('accessToken'),
        refreshTimeout
      );
    }
  };

  const authMapping = {
    [LoginType.TakedaId]: {
      validate: validateTakedaID,
      ready: isTakedaIDReady,
    },
    [LoginType.DocCheck]: {
      validate: validateDocCheck,
      ready: isDocCheckReady,
    },
    [LoginType.Unauthenticated]: {
      validate: isUnauthenticated,
      ready: isUnauthenticated,
    },
  };

  return authenticated ? (
    <>
      {locale && <VdzTermsUpdatedConfirmation />}
      {success}
    </>
  ) : !country ? (
    success
  ) : (
    loading
  );
}
