/*
 * TAKEDA CONFIDENTIAL – Highly Restricted: Do not distribute without prior approval
 *
 * © Copyright (2024) Takeda. All Rights Reserved
 */

import { dateToString, toOptionalDate } from '../../../../domain/dates/dates.domain';
import { StorageKeys } from '../../../../domain/storage/storage.constants';
import { useLocalStorage } from '../use-local-storage/use-local-storage.hook';

export const useBannerDismissDate = () => {
  const { getStorageValue, setStorageValue } = useLocalStorage();

  const dismissDate = toOptionalDate(
    getStorageValue(StorageKeys.BannerDismissDate)
  );

  const setDismissDate = (dismissDate: Date) => {
    setStorageValue(StorageKeys.BannerDismissDate, dateToString(dismissDate));
  };

  return {
    dismissDate,
    setDismissDate,
  };
};
