/*
 * TAKEDA CONFIDENTIAL – Highly Restricted: Do not distribute without prior approval
 *
 * © Copyright (2024) Takeda. All Rights Reserved
 */

import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { getCurrentDate, toOptionalDate } from '../../../../domain/dates/dates.domain';
import { getCountryConfiguration } from '../../../../domain/global-config.domain';
import { VdzAnchor } from '../../../shared/components/anchor.component';
import { VdzModal } from '../../../shared/components/modal.component';
import { useGlobalConfig } from '../../../shared/hooks/use-global-config/use-global-config.hook';
import { useLocale } from '../../../shared/hooks/use-locale/use-locale.hook';
import {
  useTermsAcceptanceDate,
} from '../../../shared/hooks/use-terms-acceptance-date/use-terms-acceptance-date.hook';

export const VdzTermsUpdatedConfirmation = () => {
  const { t } = useTranslation();
  const { globalConfig } = useGlobalConfig();
  const { country } = useLocale();
  const { acceptanceDate, setAcceptanceDate } = useTermsAcceptanceDate();

  const countryConfig = getCountryConfiguration(
    country,
    globalConfig.countryConfiguration
  );

  const isVisible = () => {
    const termsUpdateDate = toOptionalDate(countryConfig?.termsUpdateDate);
    return (
      country &&
      (!acceptanceDate || (termsUpdateDate && termsUpdateDate > acceptanceDate))
    );
  };

  const onClose = () => {
    setAcceptanceDate(getCurrentDate());
  };

  return (
    <VdzModal
      containerClassName="text-center"
      title={
        !acceptanceDate
          ? 'footer.terms_of_use.title'
          : 'terms_acceptance.confirmation.title'
      }
      buttonText="terms_acceptance.confirmation.accept"
      isVisible={!!isVisible()}
      onClose={onClose}
    >
      <Trans
        i18nKey="terms_acceptance.confirmation.body"
        components={{
          anchor: <VdzAnchor href={t('footer.terms_of_use.href') || ''} />,
        }}
        shouldUnescape={true}
        values={{ address: t('footer.terms_of_use.title') }}
      ></Trans>
    </VdzModal>
  );
};
