/*
 * TAKEDA CONFIDENTIAL – Highly Restricted: Do not distribute without prior approval
 *
 * © Copyright (2024) Takeda. All Rights Reserved
 */

type DateUnit = number | string | Date;

export const toDate = (dateUnit?: DateUnit) => {
  return dateUnit ? new Date(dateUnit) : new Date();
};

export const toOptionalDate = (dateUnit?: DateUnit) => {
  return dateUnit ? new Date(dateUnit) : undefined;
};

export const dateToString = (date = new Date()) => {
  return date.toISOString();
};

export const getTime = (date = new Date()) => {
  return date.getTime();
};

export const getCurrentDate = () => {
  return new Date();
};

export const getCurrentYear = () => {
  return new Date().getFullYear();
};

export const compareDates = (dateA: Date, dateB = new Date()) => {
  return dateA.getTime() - dateB.getTime();
};
